<template>
  <v-container id="login-form" class="full-height">
    <v-row justify="center" align="center" class="full-height">
      <v-col cols="auto" class="form-card white elevation-19">
        <v-row>
          <v-col><h1 class="primary--text">Login</h1></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="username"
              name="username"
              hint="username"
              solo
              label="username"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              hint="password"
              solo
              label="password"
              type="password"
              name="password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><v-btn @click="$emit('login', { username, password })">
              Login
            </v-btn></v-col
          >
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.full-height {
  min-height: 100vh;
}

.form-card {
  padding: 10px 30px;
  border: 1px solid var(--v-primary-base);
  border-radius: 10px;
}
</style>
