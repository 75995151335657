<template>
  <div class="login-screen">
    <Loader v-if="loading" />
    <DayMenu v-if="loggedIn" @upload="upload($event)"></DayMenu>
    <LoginForm v-else @login="login($event)" />
    <notifications group="login" />
  </div>
</template>

<script>
import DayMenu from "@/components/TodaysMenu/DayMenu";
import Loader from "@/components/loader/Loader";
import LoginForm from "@/components/TodaysMenu/LoginForm";
import { mapGetters } from "vuex";
import AuthenticationService from "@/services/Authentication.service";

export default {
  components: { DayMenu, Loader, LoginForm },
  computed: {
    ...mapGetters("business/", ["infos", "items"]),
    ...mapGetters("loader", ["loading"]),
    ...mapGetters("system/", ["dayMenuDialog", "dayMenuCategory"]),
    item() {
      return this.items.filter((i) => i.category === this.dayMenuCategory)[0];
    },
  },
  watch: {
    loading() {
      const greek = this.infos.availableLanguages.find(
        (l) => l.locale === "el"
      );
      this.$store.commit("system/setLanguage", greek);
    },
  },
  data() {
    return {
      loggedIn: false,
      accessToken: null,
    };
  },
  methods: {
    login(data) {
      AuthenticationService.login(data.username, data.password)
        .then((response) => {
          if (response.status === 200) {
            this.accessToken = `Bearer ${response.headers["access-token"]}`;
            this.loggedIn = true;
          }
        })
        .catch((error) => {
          this.$notify({
            group: "login",
            type: "error",
            title: "Wrong email/password",
            text: error,
          });
        });
    },
    upload(image) {
      this.$store.dispatch("loader/start");
      this.item.image = image;
      this.item.imageUrl = image;
      AuthenticationService.upload(this.item, this.accessToken).finally(() =>
        this.$store.dispatch("loader/end")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.login-screen {
  filter: blur(1);
  background-image: linear-gradient(
      0deg,
      rgb(255 255 255 / 98%),
      rgb(67 100 169 / 61%)
    ),
    url(/assets/carousel/carousel_2.jpg);
  background-position: center;
  background-size: cover;
}
</style>
