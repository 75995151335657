import axios from "axios";
let api = axios.create({
  baseURL: "https://cms.meltemi-restaurant.eu/",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  login(email, password) {
    return api.post("/admin/login", {
      email: email,
      password: password,
    });
  },
  upload(item, accessToken) {
    return api.post("/api/meals", item, {
      headers: {
        Authorization: accessToken,
      },
    });
  },
};
