<template>
  <div>
    <v-container id="form" class="full-height">
      <v-row justify="center">
        <v-col cols="auto" v-if="generatedImage">
          <img id="image" class="generated-image" :src="generatedImage" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <v-btn @click="download" :disabled="!selectedCooked"
            >download <v-icon right>fas fa-download</v-icon></v-btn
          ></v-col
        ><v-col cols="auto">
          <v-btn @click="print" :disabled="!selectedCooked"
            >print <v-icon right>fas fa-print</v-icon></v-btn
          ></v-col
        >
        <v-col cols="auto">
          <v-btn
            @click="$emit('upload', generatedImage)"
            :disabled="!selectedCooked"
            >upload <v-icon right>fas fa-arrow-up</v-icon></v-btn
          ></v-col
        >
      </v-row>
      <v-row justify="center">
        <v-col cols="6"
          ><v-select
            v-model="selectedCooked"
            :items="computedItems"
            item-value="uuid"
            @change="printDiv"
            attach
            solo
            chips
            label="Μαγειρευτά"
            multiple>
            <template v-slot:item="{ item }">
              {{ item.title[language.lcid] }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip>{{ item.title[language.lcid] }}</v-chip>
            </template>
          </v-select></v-col
        >
        <v-col cols="6"
          ><v-select
            v-model="selectedCooked"
            :items="computedFishItems"
            item-value="uuid"
            solo
            @change="printDiv"
            attach
            chips
            label="Ψάρια"
            multiple>
            <template v-slot:item="{ item }">
              {{ item.title[language.lcid] }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip>{{ item.title[language.lcid] }}</v-chip>
            </template>
          </v-select></v-col
        >
      </v-row>
      <hr class="my-10" />
    </v-container>
    <div v-if="show" id="page-wrapper">
      <div id="page" class="page">
        <div class="inner-page">
          <div class="inner-page-date-photos-logo">
            <div class="logo">
              <div class="img"></div>
            </div>
            <div class="menu-icon">
              <div class="img"></div>
            </div>
            <div class="menu-wrapper">
              <v-container v-if="selectedCooked" class="px-5">
                <v-row justify="center">
                  <v-col cols="auto"> <h4>Today's Menu</h4></v-col>
                </v-row>
                <v-row
                  v-for="(item, index) in items.filter((i) =>
                    selectedCooked.includes(i.uuid)
                  )"
                  :key="index"
                  justify="center">
                  <v-col cols="10" class="pb-1 d-flex align-baseline flex-wrap">
                    <h3>
                      {{ item.title[english.lcid] }}
                    </h3>
                    <!-- ENABLE again when you have the translations -->
                    <h4 v-for="(lang, index) in restLanguages" :key="index">
                      <span>&#x2022;</span>{{ item.title[lang.lcid] }}
                    </h4>
                  </v-col>
                  <v-col cols="2" class="price pb-1">
                    <i>{{ item.price }} <span>€</span> </i>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <div class="social">
              <v-icon>fab fa-facebook-f</v-icon>
              <v-icon>fab fa-instagram</v-icon>
              <v-icon>fab fa-tripadvisor</v-icon>
              <v-icon>fab fa-google</v-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import { mapGetters } from "vuex";
export default {
  mounted() {
    this.printDiv();
  },
  computed: {
    ...mapGetters("business/", ["items", "categories", "infos"]),
    ...mapGetters("system/", ["language"]),
    ...mapGetters("loader/", ["loading"]),
    restLanguages() {
      return this.infos.availableLanguages.filter((l) => l.code !== "gb");
    },
    english() {
      return this.infos.availableLanguages.find((l) => l.code === "gb");
    },
    coockedCategory() {
      return this.categories.filter((c) => c.uuid === 4)[0];
    },
    fishCategory() {
      return this.categories.filter((c) => c.uuid === 10)[0];
    },
    computedFishItems() {
      return this.items
        .filter((i) => [10].includes(i.category))
        .filter((i) => i.available);
    },
    computedItems() {
      return this.items
        .filter((i) => [4].includes(i.category))
        .filter((i) => i.available);
    },
  },
  data() {
    return {
      show: false,
      generatedImage: null,
      today: new Date().toISOString().split("T")[0],
      selectedCooked: null,
      disabled: false,
    };
  },
  methods: {
    itemsWithCategory(uuid) {
      return this.computedItems
        .filter((m) => m.category === uuid)
        .filter((m) => m.available);
    },
    printDiv() {
      this.show = true;
      this.$store.dispatch("loader/start");
      setTimeout(() => {
        htmlToImage
          .toJpeg(document.querySelector("#page"), { quality: 1 })
          .then((dataUrl) => {
            this.generatedImage = dataUrl;
            this.show = false;
            this.$store.dispatch("loader/end");
          }, 5000);
      });
    },
    download() {
      download(this.generatedImage, `menu-${this.today}.jpg`);
    },
    print() {
      this.show = true;
      setTimeout(() => {
        const pwin = window.open("", "_blank");
        pwin.document.write(
          "<style> img {height: 100vh;}</style>" +
            '<img src="' +
            this.generatedImage +
            '"/>'
        );

        setTimeout(() => pwin.print(), 200);
      }, 1000);
      // const image = new Image();
      // image.src = this.generatedImage;
      // const newWindow = window.open("", "_blank", "width:100vw;height:100vh");
      // const head =
      //   newWindow.document.head ||
      //   newWindow.document.getElementsByTagName("head")[0];
      // const style = newWindow.document.createElement("style");

      // head.appendChild(style);
      // style.type = "text/css";
      // const css = "img {height:100vh;}";
      // style.appendChild(document.createTextNode(css));

      // newWindow.document.body.appendChild(image);
      // setTimeout(() => newWindow.print(), 200);
    },
  },
};
</script>

<style lang="scss" scoped>
@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
    margin: 0;
  }
  #page {
    display: flex;
  }
  #form {
    display: none;
  }
}
.full-height {
  min-height: 100vh;
}
.page {
  font-family: "Advent Pro";
  position: relative;
  width: 210mm;
  max-width: 210mm;
  height: 297mm;
  max-height: 297mm;
  background: #fff;
  border-radius: 5px;
  visibility: visible;

  .inner-page {
    position: absolute;
    width: calc(100%);
    height: calc(100%);
    margin: 0 auto;
    .inner-page-date-photos-logo {
      position: relative;
      width: 100%;
      height: calc(100%);
      .date {
        text-align: center;
      }
      .logo {
        .img {
          height: 12mm;
          background-image: url(/assets/images/day-menu/inner-page-menu-logo.png);
          background-size: contain;
          background-position: center center;
        }
      }
      .olives {
        position: absolute;
        right: -2mm;
        top: 0;
        .img {
          height: 30mm;
          width: 30mm;
          background-image: url(/assets/images/day-menu/inner-page-menu-olives.png);
          background-size: contain;
          background-position: top right;
        }
      }
      .menu-icon {
        padding: 2mm 0;
        position: relative;
        .img {
          height: 10mm;
          background-image: url(/assets/images/day-menu/inner-page-menu-icon.png);
          background-size: contain;
          background-position: center center;
        }
        &::before,
        &::after {
          position: absolute;
          content: "";
          height: 0.2mm;
          top: 60%;
          width: 30%;
          background: #44bffd;
        }
        &::before {
          left: 0;
          margin-left: 10%;
        }
        &::after {
          right: 0;
          margin-right: 10%;
        }
      }
      .olive-tree {
        position: absolute;
        left: -1mm;
        bottom: -6mm;
        .img {
          opacity: 1;
          height: 20mm;
          width: 20mm;
          background-image: url(/assets/images/day-menu/inner-page-menu-olive-tree.png);
          background-size: contain;
          background-position: bottom left;
        }
      }
      .social {
        position: absolute;
        bottom: 0mm;
        right: 5mm;
        i {
          font-size: 1.5rem;
          padding-left: 3mm;
          opacity: 1;
          color: #a9baca;
        }
      }
      .menu-wrapper {
        position: absolute;
        top: 30mm;
        height: calc(100% - 90mm);
        width: 100%;
        flex-direction: column;
        h2 {
          color: rgb(0, 0, 0);
          text-transform: uppercase;
          font-size: 2rem;
          font-weight: 600;
        }

        h3 {
          font-family: "open-sans";
          color: rgb(0, 0, 0);
          text-transform: capitalize;
          font-size: 2.3rem;
        }
        h4 {
          font-family: "open-sans";
          color: #1d1d1d;
          font-size: 1.4rem;
          span {
            padding: 0 5px;
            color: #9ba6b1;
          }
        }
      }
    }
  }

  .after,
  .before {
    position: absolute;
    top: 10mm;
    height: calc(100% - 20mm);
    width: 12mm;

    .img {
      width: 100%;
      height: 100%;
      filter: drop-shadow(1px 1px 1px rgba(255, 255, 255, 0.4))
        drop-shadow(-1px -1px 1px rgba(0, 0, 0, 0.4));
      background-size: contain;
      background-repeat: round;
      background-image: url(/assets/images/lentas_left.png);
    }
  }
  .before {
    left: 0;
  }
  .after {
    .img {
      transform: rotate(180deg);
      background-position: bottom;
    }
    right: 0;
  }

  .start,
  .end {
    position: absolute;
    left: 12mm;
    height: 12mm;
    width: calc(100% - 24mm);
    .img {
      width: 100%;
      height: 100%;

      filter: drop-shadow(1px 1px 1px rgba(255, 255, 255, 0.4))
        drop-shadow(-1px -1px 1px rgba(0, 0, 0, 0.4));
      background-size: contain;
      background-repeat: round;
      background-image: url(/assets/images/lentas_top.png);
    }
  }

  .start {
    top: 0rem;
  }
  .end {
    bottom: 0rem;
    .img {
      transform: rotate(180deg);
    }
  }
}

.price {
  text-align: right;
  color: rgb(24, 24, 24);
  font-size: 2rem;
  font-weight: 600;
}
.generated-image {
  max-height: 50vh;
}
</style>
